function getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}
function eventDataController (){
    var authToken = getCookie("authKey");
        
    const analyticsEvents = {    
        getSession: function (key) {
            return sessionStorage.getItem(key)
        },
    
        setSession: function (key, value) {
            sessionStorage.setItem(key, value);
        },
    
        clearSession: function (key) {
            sessionStorage.removeItem(key);
        },
    
        getToken: function () {
            if (!authToken) {
                var url = window.location.origin + "/adobeintegration/getToken";
                const xhttpr = new XMLHttpRequest();
                xhttpr.open('GET', url, true);                
                xhttpr.send();                
                xhttpr.onload = ()=> {
                    var response = JSON.parse(xhttpr.response);
                    if (response.statusCode === "200") {
                        authToken = response.message;
                    } else {
                        console.error(response.message);
                    }
                };
            }
        },
    
        sendData: function (data, clearStorage) {
            var fullUrl = document.getElementById("AdobeIntegrationLambdaURL").value;
            // var fullUrl = 'https://pwb1w6oj83.execute-api.us-east-1.amazonaws.com/stage/collector-api';
            var xhttp = new XMLHttpRequest();
            xhttp.open("POST", fullUrl, true);
            xhttp.setRequestHeader("Content-Type", "application/json; charset=utf-8");
            xhttp.setRequestHeader("token", authToken);
            xhttp.setRequestHeader("Accept", "application/json");
            xhttp.onload = function () {
                var response = xhttp.response;
                if (xhttp.status === 200) {
                    console.log("Status: " + response);
                    if(clearStorage){
						for(var session of clearStorage){
							analyticsEvents.clearSession(session);
						}
					}
                } else {
                    console.error(response);
                }
            };
            xhttp.send(JSON.stringify(data));
        }        
    }
    analyticsEvents.getToken();

    const eventData = {
        "timestamp": Date.now(),
        "userAgent": navigator.userAgent,
        "pageName": analytics_data.page_name
    }
    const analyticsData = JSON.parse(JSON.stringify(analytics_data));
    
    return {analyticsEvents, eventData, analyticsData};
};
export default eventDataController;